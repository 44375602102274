<template>

  <div>
    <answer-list-add-new
      :is-add-new-answer-sidebar-active.sync="isAddNewAnswerSidebarActive"
      :answer-data="answerData"
      @reset-data="resetAnswerData"
      @refetch-data="getAnswerData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="7"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="getAnswerData"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="5"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @keyup.enter="getAnswerData()"
              />
              <b-button
                variant="primary"
                @click="isAddNewAnswerSidebarActive = true"
              >
                <span class="text-nowrap">Add Template</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refAnswerListTable"
        class="position-relative"
        :items="answerList"
        responsive
        :fields="tableColumns"
        primary-key="template_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="getAnswerData"
      >

        <!-- Column: User -->
        <template #cell(message)="data">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="small mb-50"
            v-html="data.item.message"
          />
          <!-- eslint-enable -->
        </template>

        <!-- Column: Updated At -->
        <template #cell(updated_at)="data">
          <div class="text-nowrap">
            {{ data.item.updated_at | formatDate }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="editData(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="confirmDel(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AnswerListAddNew from './AnswerListAddNew.vue'

// Notification

export default {
  components: {
    AnswerListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const toast = useToast()
    const isAddNewAnswerSidebarActive = ref(false)

    // Table Handlers
    const tableColumns = [
      { key: 'title', sortable: true },
      { key: 'message', sortable: true },
      { key: 'author', sortable: true },
      { key: 'updated_at', label: 'Last Update', sortable: true },
      { key: 'actions' },
    ]

    const perPage = ref(25)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('template_id')
    const isSortDirDesc = ref(true)

    return {
      toast,
      // Sidebar
      isAddNewAnswerSidebarActive,

      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
    }
  },
  data() {
    return {
      faqTags: [],
      answerList: [],
      dataMeta: '',
      answerData: {},
    }
  },
  created() {
    this.getAnswerData()
  },
  methods: {
    onPageChange(params) {
      window.scrollTo(0, 0)
      this.currentPage = params
      this.getAnswerData()
    },
    getAnswerData() {
      document.getElementById('loading-bg').style.display = 'block'
      this.answerData = {}

      const params = {
        length: this.perPage,
        filters: [],
      }
      if (this.searchQuery) {
        params.keyword = this.searchQuery
      }
      if (this.sortBy) {
        params.sort_by = [{
          column: this.sortBy,
          asc_desc: (this.isSortDirDesc) ? 'desc' : 'asc',
        }]
      }
      let target = '/templates'
      if (this.currentPage !== 1) {
        target += `?page=${this.currentPage}`
      }
      if (this.faqTags.length === 0) {
        this.$http.post('ref/tg', true, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        })
          .then(resp => {
            if (resp.status === 200) {
              this.faqTags = resp.data
            }
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Tag list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
      this.$http.post(target, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.answerList = resp.data.data
            this.dataMeta = resp.data.meta
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching FAQ list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editData(data) {
      this.answerData = data
      this.isAddNewAnswerSidebarActive = true
    },
    resetAnswerData() {
      this.answerData = {}
    },
    confirmDel(data) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http.post('/template/delete', { template_id: data.template_id }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          })
            .then(resp => {
              if (resp.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your file has been deleted.',
                  showConfirmButton: false,
                })
                setTimeout(() => {
                  this.getAnswerData()
                  this.$swal.close()
                }, 1000)
              }
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Deleting Data',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
